import * as firebase from "firebase/app";
import "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAx8NqxjACtPsRNCp6Qg5UHONcjD2KDsNI",
  projectId: "yourcanvas-website",
  appId: "1:339246351623:web:bb0bb909ab7b059934f1a2",
  measurementId: "G-BJT2YC6NSV",
};

firebase.initializeApp(firebaseConfig);
const analytics = firebase.analytics();

const logEvent = (event) => {
  analytics.logEvent(event);
};

export default { logEvent };
