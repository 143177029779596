export default () => {
  const menu = document.querySelector("#mobile-menu");
  document.querySelector("#menu-hamburger").addEventListener("click", () => {
    menu.classList.add("active");
  });

  document.querySelector("#menu-back").addEventListener("click", () => {
    menu.classList.remove("active");
  });
};
