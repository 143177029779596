import { logEvent } from "../firebase";

export default () => {
  document.querySelectorAll("[data-button-signup]").forEach((element) => {
    element.addEventListener("click", function (event) {
      event.preventDefault();

      requestAnimationFrame(() => {
        const formInput = document.querySelector(
          ".signup-form input:first-child"
        );
        if (!formInput) {
          window.location = "/";
          return;
        }

        logEvent("signup_on_form_focussed_with_button");
        document.querySelector(".hero").scrollIntoView({
          block: "start",
          inline: "nearest",
          behavior: "smooth",
        });

        formInput.focus();
      });
    });
  });
};
