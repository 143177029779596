import initNotifications from "./vanilla/notifications";
import initHeroTextSlides from "./vanilla/hero-text-slides";
import initMobileNavigation from "./vanilla/mobile-nav";
import initIntroAnimations from "./vanilla/animate-intro";
import initTryForFree from "./vanilla/try-for-free-banner";
import initScrollToSignupForm from "./vanilla/scroll-to-signup";
import initScrollDownIndicator from "./vanilla/scroll-down-indicator";

import "./firebase";
import "./google-tag-manager-head";

window.addEventListener("DOMContentLoaded", (event) => {
  initNotifications();
  initHeroTextSlides();
  initMobileNavigation();
  initIntroAnimations();
  initTryForFree();
  initScrollToSignupForm();
  initScrollDownIndicator();
});
