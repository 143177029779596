import throttle from "lodash/throttle";

const w = window;

export default () => {
  (function () {
    let iconElement;
    let windowHeight;

    function init() {
      iconElement = document.querySelector(".icon-arrow-indicator");
      windowHeight = w.innerHeight;
    }

    function showElement() {
      iconElement.classList.add("visible");
    }

    function hideElement() {
      iconElement.classList.remove("visible");
    }

    const checkPosition = throttle(() => {
      if (window.scrollY < 100) {
        requestAnimationFrame(showElement);
      } else {
        requestAnimationFrame(hideElement);
      }
    }, 33);

    w.addEventListener("scroll", checkPosition);
    w.addEventListener("resize", init);

    init();
    checkPosition();
  })();
};
