import throttle from "lodash/throttle";

const w = window;

export default () => {
  (function () {
    let elements;
    let windowHeight;

    function init() {
      elements = document.querySelectorAll("[data-animated]");
      windowHeight = w.innerHeight;
    }

    function addAnimatedClass() {
      this.classList.add("fade-in-bottom");
    }

    const checkPosition = throttle(() => {
      for (let i = 0; i < elements.length; i++) {
        const element = elements[i];
        const positionFromTop = elements[i].getBoundingClientRect().top;

        if (positionFromTop - windowHeight <= 0) {
          requestAnimationFrame(addAnimatedClass.bind(element));
        }
      }
    }, 33);

    w.addEventListener("scroll", checkPosition);
    w.addEventListener("resize", init);

    init();
    checkPosition();
  })();
};
