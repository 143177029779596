export default () => {
  const heroTexts = document.querySelectorAll(".hero-text");
  if (heroTexts.length) {
    const currentOrderHeroTexts = [0, 1, 2];
    function updateHeroTexts() {
      currentOrderHeroTexts.push(currentOrderHeroTexts.shift());
      heroTexts.forEach((heroText, index) => {
        heroText.className = `hero-text hero-text-${currentOrderHeroTexts[index]}`;
      });

      setTimeout(updateHeroTexts, currentOrderHeroTexts[0] === 2 ? 3000 : 1500);
    }

    updateHeroTexts();
  }
};
