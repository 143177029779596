import throttle from "lodash/throttle";

const w = window;

export default () => {
  return;
  (function () {
    let triggerElement;
    let popupElement;
    let windowHeight;

    function init() {
      triggerElement = document.querySelector("[data-try-for-free]");
      popupElement = document.querySelector(".try-for-free-floating");
      windowHeight = w.innerHeight;
    }

    function showElement() {
      popupElement.classList.add("visible");
    }

    function hideElement() {
      popupElement.classList.remove("visible");
    }

    const checkPosition = throttle(() => {
      const positionFromTop = triggerElement.getBoundingClientRect().top;
      if (positionFromTop - windowHeight <= 0) {
        requestAnimationFrame(hideElement);
      } else {
        requestAnimationFrame(showElement);
      }
    }, 33);

    w.addEventListener("scroll", checkPosition);
    w.addEventListener("resize", init);

    init();
    checkPosition();
  })();
};
