export default () => {
  const notifications = document.querySelectorAll(".notification");
  if (notifications.length) {
    const currentOrder = [0, 1, 2, 3, 4];
    function updateNotifications() {
      currentOrder.push(currentOrder.shift());
      notifications.forEach((notification, index) => {
        notification.className = `notification notification-${currentOrder[index]}`;
      });

      setTimeout(updateNotifications, 2000);
    }

    updateNotifications();
  }
};
